;(function($,window,document,undefined)
{
  $.fn.mobileDoubleTap = function( args ){
    $('#mainnav > li.has-children')
      .on('touchstart',function(event){
        if(!$(this).hasClass('mobile-hover')){
          event.stopPropagation(); event.preventDefault();
        }
        $(this).toggleClass('mobile-hover');
      }
    );
    return this;
  };
})(jQuery, window, document);
